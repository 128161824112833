
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { VuetifyMixin, BAIconsMixin, FormRulesMixin, StatusMixin, StringsMixin, PaginatedTableMixin, AdminRoutingMixin } from '@/mixins';
import { EventLocationModel } from "@/models/baEvent";
import { DataTableHeader } from 'vuetify';
import { RepositoryQuery, QueryOptions, PaginatedResponse } from '@/../types';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { eventLocationApi } from '@/api/BAEventsApi';
import LoadingWait from '@/components/ui/LoadingWait.vue';

@Component({
	components: {
		Page,
        FileUploadArea,
	}
})
export default class BAEventLocationAdminPage extends Mixins(VuetifyMixin, StringsMixin, FormRulesMixin, StatusMixin, BAIconsMixin, PaginatedTableMixin, AdminRoutingMixin){
    eventLocations: Array<EventLocationModel> = [];
    search: string = '';
    
    mounted() {
        this.tableOptions.sortBy = ['name'];
        this.tableOptions.sortDesc = [true];
        this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 25]];
    }

    get TableHeaders(): Array<DataTableHeader<any>> {
        let headers: Array<DataTableHeader<any>> = [
            { text: 'Name', value: 'name', sortable: false },
            { text: 'City', value: 'city', sortable: false },
            { text: 'State', value: 'state', sortable: false },
            { text: 'Country', value: 'country', sortable: false },
            { text: 'Website', value: 'url', sortable: false },
            { text: 'Google', value: 'google', sortable: false },
        ];
        if( this.IsLargeScreen ) {
            headers.push({text: '', value: 'actions', sortable: false });
            headers.push({text: '', value: 'data-table-expand', sortable: false});
        }
        return headers;
    }

    async loadTable() {
        this.isLoading = true;
        this.isLoaded = false;
        const query: RepositoryQuery<EventLocationModel> = this.TableQuery<EventLocationModel>(this.search, ['name', 'city', 'state', 'country', 'notes']);;
        // query.$match = {...query.$match, ...{ key: value }};
        const options: QueryOptions = this.TableQueryOptions;
        const response: PaginatedResponse<EventLocationModel> = await eventLocationApi.queryAll(query, options);
        this.eventLocations = response.docs;
        this.dataItemsCount = response.total;
        this.isLoaded = true;
        this.isLoading = false;
    }
    get TableLoading(): boolean {
        return this.isLoading;
    }
    get PageLoading(): boolean {
        return this.TableLoading || this.isLoading;
    }

    editTitle: string = "Edit Location";
    backupLocation: EventLocationModel;
    editLocation: EventLocationModel = new EventLocationModel;
    editingLocation: boolean = false;
    onAddLocation() {
        this.editTitle = "Add Location";
        this.editLocation = new EventLocationModel();
        this.editingLocation = true;
    }
    onEditLocation(location: EventLocationModel) {
        this.editTitle = `Edit ${location.name}`;
        this.backupLocation = new EventLocationModel;
        Object.assign(this.backupLocation, location);
        this.editLocation = location;
        this.editingLocation = true;
    }
    async onDeleteLocation(location: EventLocationModel) {
        if( !confirm(`Delete ${location.name}? This action cannot be undone`) ) return;

        await eventLocationApi.delete(location);
        this.updateTable();
    }

    onCancelEditDlg() {
        Object.assign(this.editLocation, this.backupLocation);
        this.editingLocation = false;
    }
    async onSaveEditDlg() {
        if( !(this.$refs.form as HTMLFormElement).validate() ) {
            this.setError( "Please complete all of the required fields" );
            return;
        }

        await eventLocationApi.save(this.editLocation);
        this.editingLocation = false;
        this.updateTable();
    }
    async onChangeLocationOnline() {
        this.editLocation.address = this.editLocation.online? "Online" : this.backupLocation.address;
    }
}
